import "./styles.css";

const form = document.querySelector("form");
const fileInput = document.getElementById("mediacontent");
const submitButton = document.querySelector("button");
const statusMessage = document.getElementById("statusMessage");
const fileListMetadata = document.getElementById("fileListMetadata");
const fileNum = document.getElementById("fileNum");
const progressBar2 = document.getElementById("progressBarBS");
const dropArea = document.getElementById("dropArea");
const uploadstatus = document.getElementById("uploadstatus");
const sussessMsgElement = document.getElementById("sussessMsg");
const formPartition = document.getElementById("forn-partition");



form.addEventListener("submit", handleSubmit);
fileInput.addEventListener("change", handleInputChange);
dropArea.addEventListener("drop", handleDrop);

initDropAreaHighlightOnDrag();

function handleSubmit(event) {
  event.preventDefault();


  showPendingState();

  uploadFiles(fileInput.files);
}

function handleDrop(event) {
  const fileList = event.dataTransfer.files;

  resetFormState();

  try {
    assertFilesValid(fileList);
  } catch (err) {
    updateStatusMessage(err.message);
    return;
  }

  showPendingState();

  uploadFiles(fileList);
}

function handleInputChange(event) {
  resetFormState();

  try {
    assertFilesValid(event.target.files);
  } catch (err) {
    updateStatusMessage(err.message);
    return;
  }

  //submitButton.disabled = false;
}

function uploadFiles(files) {
  const url = process.env.SP_SENTENDPOINT;
  const method = "post";

  formPartition.attributes.setNamedItem(document.createAttribute("hidden"));
  uploadstatus.attributes.removeNamedItem("hidden");
  const xhr = new XMLHttpRequest();

  xhr.upload.addEventListener("progress", (event) => {
    // convert bytes to megabytes
    var loadedtoMb = event.loaded / 1024 / 1024;
    //round to 2 decimal places
    loadedtoMb = Math.round(loadedtoMb * 100) / 100;
    var totaltoMb = event.total / 1024 / 1024;
    totaltoMb = Math.round(totaltoMb * 100) / 100;
    updateStatusMessage(`⏳ Náhráno ${loadedtoMb} MB z ${totaltoMb} MB`);
    updateProgressBar(event.loaded / event.total);
  });

  xhr.addEventListener("loadend", () => {
    if (xhr.status === 200) {
      updateStatusMessage("✅ Úspěšně nahráno");
      renderFilesMetadata(files);
      // remove hidden attribute
      sussessMsgElement.attributes.removeNamedItem("hidden");

      // add hidden attribute
      uploadstatus.attributes.setNamedItem(document.createAttribute("hidden"));

    } else {
      updateStatusMessage("❌ Došlo k chybě");
      formPartition.attributes.removeNamedItem("hidden");

    }

    updateProgressBar(0);
  });

  const data = new FormData();

  Array.from(form.elements).forEach((input) => {
    if (input.type === "file") return;

    data.append(input.name, input.value);
  });

  for (const file of files) {
    data.append("videos", file);
  }

  xhr.open(method, url);
  xhr.send(data);
}

function renderFilesMetadata(fileList) {
  fileNum.textContent = fileList.length;

  fileListMetadata.textContent = "";

  for (const file of fileList) {
    const name = file.name;
    const type = file.type;
    const size = file.size;

    fileListMetadata.insertAdjacentHTML(
      "beforeend",
      `
        <li  class="list-group-item d-flex justify-content-between align-items-center">
          <strong>Soubor:</strong> ${name}
        
         <span class="badge badge-primary badge-pill">${Math.round((size / 1024 / 1024) * 100) / 100} MB</span> 
        </li>`
    );
  }
}

function assertFilesValid(fileList) {
  const allowedTypes = ["image/webp", "image/jpeg", "image/png", "video/mp4", "video/ogg", "video/webm", "video/quicktime", "video/x-msvideo", "video/x-ms-wmv", "video/x-matroska"];
  const sizeLimit = 1024 * 1024 * 1024; // x megabyte

  for (const file of fileList) {
    const { name: fileName, size: fileSize } = file;

    if (!allowedTypes.includes(file.type)) {
      throw new Error(
        `❌ Soubor "${fileName}" nelze nahárt. Povolené typy souborů jsou mediální soubory (*.avi, *.mp4, *.jpeg a podobné). `
      );
    }

    if (fileSize > sizeLimit) {
      throw new Error(
        `❌ Soubor "${fileName}" nelze nahrát. Maximální velikost souboru je ${sizeLimit / 1024 / 1024} MB.`
      );
    }
  }
}

function updateStatusMessage(text) {
  statusMessage.textContent = text;
}

function updateProgressBar(value) {
  const percent = value * 100;

  //progressBar2.style.width = Math.round(percent) + " %";
  var widthst = "width: " + Math.round(percent) + "%";

  progressBar2.setAttribute("style", widthst);
  progressBar2.textContent = Math.round(percent) + " %";
}

function showPendingState() {
  submitButton.disabled = true;
  updateStatusMessage("⏳ Připravuje se ...");
}

function resetFormState() {
  fileListMetadata.textContent = "";
  fileNum.textContent = "0";

  //submitButton.disabled = true;
  updateStatusMessage(`🤷‍♂ Nic k nahrání`);
}

function initDropAreaHighlightOnDrag() {
  let dragEventCounter = 0;

  dropArea.addEventListener("dragenter", (event) => {
    event.preventDefault();

    if (dragEventCounter === 0) {
      dropArea.classList.add("highlight");
    }

    dragEventCounter += 1;
  });

  dropArea.addEventListener("dragover", (event) => {
    event.preventDefault();

    // in case of non triggered dragenter!
    if (dragEventCounter === 0) {
      dragEventCounter = 1;
    }
  });

  dropArea.addEventListener("dragleave", (event) => {
    event.preventDefault();

    dragEventCounter -= 1;

    if (dragEventCounter <= 0) {
      dragEventCounter = 0;
      dropArea.classList.remove("highlight");
    }
  });

  dropArea.addEventListener("drop", (event) => {
    event.preventDefault();

    dragEventCounter = 0;
    dropArea.classList.remove("highlight");
  });
}
